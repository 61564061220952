
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

class DuoSection extends React.Component {
	render() {
		let classes = Classnames([
			'duo-section',
			{
				'duo-section--login': this.props.login,
			},
			{
				'duo-section--success': this.props.success,
			},
			{
				'duo-section--success-email-verified': this.props.successEmailVerified,
			},
			{
				'duo-section--organisation-not-found': this.props.organisationNotFound,
			},
			{
				'duo-section--register': this.props.register,
			},
			{
				'duo-section--banned': this.props.ProfileBanned,
			},
			{
				'duo-section--deleted': this.props.ProfileDeleted,
			},
			{
				'duo-section--ballots': this.props.BallotsSubmitted,
			},
			{
				'duo-section--ballots-pay': this.props.BallotsPay
			},
			{
				'duo-section--imported': this.props.RegisterImported,
			},
			{
				'duo-section--event': this.props.AdminEvent,
			},
			{
				'duo-section--admin-form': this.props.adminForm,
			},
			{
				'duo-section--profile-change-password': this.props.ProfileChangePassword,
			},
			{
				'duo-section--contact': this.props.Contact,
			},
			{
				'duo-section--reg-carer-success': this.props.RegCarerSuccess,
			},
		])

		return (
			<section className={classes}>
				{this.props.children}
			</section>
		)
	}
}

export default DuoSection
