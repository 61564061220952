
// Libraries
import * as React from 'react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import DuoSection from '../components/duoSection'
import {Link} from "gatsby"

class ResetPasswordSuccessPage extends React.Component {
	render() {
		return <Layout className="page--success nav-blue-half no-footer">
			<Seo title="Reset Password Success" />
			<DuoSection success>
				<div>
					<div className="content-wrap">
						<h1>Success!</h1>
						<p>Your password has been reset.</p>
						<Link to="/login" className="link--underline">Login</Link>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default ResetPasswordSuccessPage
